//var { navigate } = require("gatsby")
require("prismjs/themes/prism.css")

const inject = (location) => {
    const triggerElement = document.getElementById("api-doc-content")
    if(triggerElement && document.querySelectorAll){
    const headers = document.querySelectorAll('h2, h3')
    let offsets = []
    let ids = []
    let firstLevelIds = []
    let firstLevelHeader = null
    for( let i = 0; i < headers.length; i++ ) {
        ids.push(headers[i].id)
        offsets.push(headers[i].offsetTop);
        if(headers[i].nodeName.toLowerCase() === "h2"){
        firstLevelHeader = headers[i].id;
        }

        firstLevelIds.push(firstLevelHeader);
    }

    var sheet = window.document.styleSheets[0];

    const initialOffset = window.pageYOffset

    const initialIndex = offsets.findIndex((value) => value >= initialOffset);
    const initialHash = ids[initialIndex > -1 ? initialIndex : 0]
    const initialSectionHash = firstLevelIds[initialIndex > -1 ? initialIndex : 0]


    const highlightRuleIndex = sheet.insertRule(`li>a[href='#${initialHash}'] { background-color: #298f8d57; }`, sheet.cssRules.length);
    const sectionRuleIndex = sheet.insertRule(`a[href='#${initialSectionHash}']+ul { display: flex!important; }`, sheet.cssRules.length);
    let lastOffset = -100;

    let onScrollHandler = (e) => {
        const offset = window.pageYOffset
        if(Math.abs(lastOffset - offset) < 50) return;

        lastOffset = offset;
        const idx = offsets.findIndex((value) => value >= offset);
        const newHash = ids[idx > -1 ? idx : 0]
        //const oldHash = location.hash.replace('#', '')
        const sectionHash = firstLevelIds[idx > -1 ? idx : 0]

    //   if(oldHash !== newHash && location.pathname.indexOf("/api/docs") !== -1){
    //       navigate(`${location.pathname}#${newHash}`, { replace: true})
    //   }

        sheet.cssRules[highlightRuleIndex].selectorText = `li>a[href='#${newHash}']`
        sheet.cssRules[sectionRuleIndex].selectorText = `a[href='#${sectionHash}']+ul`
    }

    document.addEventListener(
        'scroll',
        onScrollHandler,
        true // Capture event
    );

    const unregister = () => {
        document.removeEventListener(
            'scroll',
            onScrollHandler,
            true
        );
    }

    return unregister;
    }
  } 

let unregister = null;
let particles_load_failed_count = 0;

const loadParticles = () => {
    console.log('loading particles...', particles_load_failed_count)
    if(particles_load_failed_count > 8){
        return;
    }

    if(window.particlesJS){
        window.particlesJS.load('particles-js', 'static/particles.json')
        particles_load_failed_count = 0;
        console.log('loading particles...SUCCESS');
    }
    else{
        particles_load_failed_count = particles_load_failed_count + 1;
        setTimeout(loadParticles, 1000);
    }
}

exports.onRouteUpdate = ({ location }) => {
    if(location.pathname.indexOf("/api/docs") !== -1){
        if(unregister !== null){
            unregister();
        }
        console.log('register')
        unregister = inject(location);
    }
    else{
        if(unregister){
            console.log('unregister')
            unregister();
            unregister = null;
        }

        if(location.pathname === "/"){
            loadParticles();
        }
    }
}