// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/hgr/ws/website-js/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-info-doc-template-js": () => import("/Users/hgr/ws/website-js/src/templates/infoDocTemplate.js" /* webpackChunkName: "component---src-templates-info-doc-template-js" */),
  "component---src-templates-api-doc-template-js": () => import("/Users/hgr/ws/website-js/src/templates/apiDocTemplate.js" /* webpackChunkName: "component---src-templates-api-doc-template-js" */),
  "component---src-info-pages-api-mdx": () => import("/Users/hgr/ws/website-js/src/info-pages/api.mdx" /* webpackChunkName: "component---src-info-pages-api-mdx" */),
  "component---src-info-pages-company-mdx": () => import("/Users/hgr/ws/website-js/src/info-pages/company.mdx" /* webpackChunkName: "component---src-info-pages-company-mdx" */),
  "component---src-info-pages-contact-mdx": () => import("/Users/hgr/ws/website-js/src/info-pages/contact.mdx" /* webpackChunkName: "component---src-info-pages-contact-mdx" */),
  "component---src-pages-404-js": () => import("/Users/hgr/ws/website-js/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-explorer-js": () => import("/Users/hgr/ws/website-js/src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-index-js": () => import("/Users/hgr/ws/website-js/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noscript-js": () => import("/Users/hgr/ws/website-js/src/pages/noscript.js" /* webpackChunkName: "component---src-pages-noscript-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/hgr/ws/website-js/.cache/data.json")

